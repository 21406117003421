.main-content {
    height: 100vh; 
    padding: 50px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
  }

  .main-content h2{
    font-size: 2rem;
  }
  
  .level-header {
    background-color: #001e76;
    padding: 20px;
    border-radius: 12px;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    font-weight: 900;
    position: sticky; 
    top: 0;
  }

  .level-header h2 {
    color: #fff !important;
    margin-bottom: 0px !important;
  }

  .section-content{
    display: flex;
    justify-content: space-around;
    width: 75%;
  }
  
  .progress-path {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    overflow-y: auto;
  }
  
  .level-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 400px;
    overflow-y: scroll;
    scrollbar-width: none;
  }
  
  .level-info {
    margin-bottom: 30px;
    min-width: 800px;
  }
  
  
  .snake-path {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .section-icon {
    width: 80px;
    height: auto;
    margin: 20px;
    transition: transform 0.2s;
    position: relative;
    cursor: pointer;
  }
      
  .robot {
    display: flex;
    align-items: center;
    margin-right: -280px;
    width: 350px;
  }

  .robot-final-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .robot-final{
    width: 250px;
  }

  .section-tooltip {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 8px 12px;
    background-color: #333;
    color: #fff;
    border-radius: 6px;
    font-size: 0.9rem;
    white-space: nowrap;
    z-index: 10;
    pointer-events: none;
  }
  
  .section-icon:hover {
    transform: scale(1.1);
  }

  .section-icon.active {
    opacity: 1;
    pointer-events: auto;
  }
  
  .section-icon.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .level-info.disabled .level-header{
    background: grey;
  }

  .level-info.disabled .robot{
    opacity: 0.5;
  }

  .level-info.disabled .robot-final{
    opacity: 0.5;
  }

  .robot-final-container.disabled{
    opacity: 0.5;
    cursor: not-allowed;
  }

  .sentinel {
    width: 100%;
    height: 10px;
  }
  
  .load-message {
    padding: 10px 20px;
    font-size: 1.2rem;
    color: #333;
    text-align: center;
    background-color: #f0f0f0;
    border-radius: 8px;
    margin-top: 20px;
    animation: bounce 0.5s ease-in-out 1;
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-20px);
    }
    60% {
      transform: translateY(-10px);
    }
  }
  
  .section-tooltip {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 8px 12px;
    background-color: #333;
    color: #fff;
    border-radius: 6px;
    font-size: 0.9rem;
    white-space: nowrap;
    z-index: 10;
    pointer-events: none;
  }

  .final-project-title{
    display: flex;
    justify-content: center;
  }

  .final-project-title h3{
    font-size: 2rem;
    margin-bottom: 20px;
    font-weight: bold;
  }

  .btn-start-course {
    padding: 10px 20px;
    border: none;
    border-radius: 50px;
    background-color: #6f42c1;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
  }
  
  
  
  