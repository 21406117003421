.advance-section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
    width: 100%;
    height: 100vh;
    background-color: #f5f5f5;
    box-sizing: border-box;
  }
  
  .message {
    font-size: 24px;
    color: #2e3c70;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .next-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 18px;
    background-color: #4a73ba;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .next-button:hover {
    background-color: #365a90;
  }
  
  .robot-container-section {
    margin-top: 30px;
  }
  
  .robot-image-section {
    width: 400px;
  }
  