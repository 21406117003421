.exercise-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
    position: relative;
    box-sizing: border-box;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px;
  }
  
  .progress-bar {
    width: 60%;
    height: 8px;
    background-color: #ddd;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
  }
  
  .progress {
    width: 50%; /* Ajusta este porcentaje según el progreso */
    height: 100%;
    background-color: #4a73ba;
    border-radius: 5px;
  }
  
  .heart-icon {
    font-size: 28px;
    color: #ff3b3b;
  }
  
  .laptop-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .laptop-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    width: 90%;
    max-width: 600px;
    height: 400px;
    border-radius: 20px;
    padding: 20px;
    color: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  }
  
  .question {
    font-size: 20px;
    font-family: 'Courier New', Courier, monospace;
    color: #cccccc;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .options {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }
  
  .option-button {
    background-color: #2e3c70;
    color: #ffffff;
    border: none;
    padding: 15px;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: left;
    width: 100%;
  }

  .option-button.correct-answer {
    border: 2px solid green;
  }
  
  
  .option-button:hover {
    background-color: #1f2a4b;
  }
  