.teacher-new-school {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
    padding: 50px;
}

.teacher-new-school h1 {
    font-size: 24px;
    color: #2e3a6f;
    margin-bottom: 20px;
}

.institution-input {
    width: 300px;
    height: 40px;
    font-size: 16px;
    color: #9e9e9e;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 0 15px;
    outline: none;
}

.institution-input::placeholder {
    color: #b3b3b3;
}

.join-button {
    margin-top: 25px;
    background-color: #2e3a6f;
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.join-button:hover {
    background-color: #1f2950;
    transform: scale(1.05);
}

.join-button:active {
    transform: scale(0.95);
}

.join-button:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(46, 58, 111, 0.5);
}

.close-container{
    display: flex;
    justify-content: flex-end;
    width: 100%;
}