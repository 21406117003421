/* General */
.home-container {
    display: flex;
    height: 100vh;
    overflow: hidden; 
  }
  
  /* Sidebar - Izquierda */
  .sidebar {
    width: 20%;
    padding: 30px 20px;
    background-color: #f8f9fa;
    position: sticky;
    top: 0;
    height: 100vh;
  }

  .sidebar-header{
    display: flex;
    justify-content: space-between;
  }
  
  .logo {
    font-size: 4rem;
    color: #0056b3;
    font-weight: 900; 
    margin-bottom: 40px;
  }
  
  .nav-list {
    list-style: none;
    padding: 0;
  }
  
  .nav-list li {
    margin: 25px 0;
    text-decoration: none;
    font-size: 1.5rem;
    color: #333;
    font-weight: 700; 
    cursor: pointer;
  }
  
  
  .league {
    margin-top: 60px;
  }
  
  .league h2 {
    font-size: 1.8rem;
    font-weight: 800;
    margin-bottom: 20px;
  }
  
  .ranking {
    list-style: none;
    padding: 0;
    font-size: 1.2rem;
  }
  
  .ranking li {
    margin: 12px 0;
    font-size: 1.1rem;
    font-weight: 600; 
  }

  .ranking-item.current-user {
    background-color: #d1e7dd;
    color: #0f5132;
    border-radius: 5px;
    padding: 5px 10px;
    font-weight: 700;
  }
  
  .ranking-item.first-place {
    color: #ffc107;
    font-weight: 800;
  }
  
  /* SidebarRight - Derecha */
  .sidebar-right {
    width: 20%;
    padding: 30px;
    background-color: #f8f9fa;
    position: sticky;
    top: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .icons {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-bottom: 40px;
  }
  
  .icons span {
    font-size: 1.5rem;
  }
  
  .web-preview {
    text-align: center;
  }
  
  .web-preview h3 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .preview-images img {
    width: 90%;
    margin-bottom: 15px;
  }
  