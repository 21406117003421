.clase-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
    position: relative;
    box-sizing: border-box;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
  }

  .header-buttons{
    display: flex;
    gap: 15px;
  }
  
  .progress-bar {
    width: 80%;
    height: 8px;
    background-color: #ddd;
    border-radius: 5px;
    margin-top: 10px;
  }
  
  .progress {
    width: 50%; /* Ajusta este porcentaje según el progreso */
    height: 100%;
    background-color: #4a73ba;
    border-radius: 5px;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding: 40px;
    width: 100%;
  }
  
  .text-container {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .text-container h2 {
    font-size: 48px;
    color: #2e3c70;
    margin-bottom: 10px;
  }
  
  .text-container p {
    font-size: 24px;
    color: #2e3c70;
  }
  
  .images-container {
    display: flex;
    justify-content: space-between;
    flex: 1;
    padding-top: 20px;
    width: 80%;
  }
  
  .message-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .speech-bubble {
    background: #ffffff;
    border-radius: 25px;
    border: 2px solid #000;
    padding: 20px 40px;
    font-size: 20px;
    max-width: 400px;
    text-align: left;
    margin-bottom: 20px;
  }
  
  .robot-image-class {
    width: 420px;
  }
  
  .books-image {
    max-width: 250px;
    margin-left: 40px;
  }
  