.teacher-home {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
    min-height: 100vh;
  }
  
  .teacher-home h1 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
  }
  
  .school-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    max-width: 600px;
  }
  
  .school-card {
    background-color: #2e3a6f;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    border-radius: 8px;
    font-weight: bold;
    text-align: center;
    font-size: 18px;
  }
  
  .add-new {
    background-color: transparent;
    border: 2px solid #2e3a6f;
    color: #2e3a6f;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .add-new:hover {
    background-color: #2e3a6f;
    color: white;
  }
  
  .add-icon {
    font-size: 36px;
  }
  
  .add-text {
    font-size: 12px;
    position: absolute;
    bottom: -20px;
    white-space: nowrap;
  }
  