@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}




h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  margin-bottom: 20px;
  font-family: 'Arial', sans-serif;
  font-weight: 800;
  font-size: 5rem;
  color: #001e76;
}

p {
  line-height: 1.6;
  margin-bottom: 15px;
  color: #555;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  background-color: #f5f5f5;
  padding: 4px 6px;
  border-radius: 4px;
}

.main-container{
  flex-grow: 1;
  overflow: scroll;
}

.logo {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 300px;
}

img {
  max-width: 100%;
}

.logo-sideBar {
  display: flex;
  width: 200px;
  margin-left: -35px;
}

::-webkit-scrollbar {
  display: none;
}

.logout-section {
  display: flex;
  cursor: pointer;
  justify-content: center;
}

.logout-icon {
  width: 24px;
  margin-right: 10px;
}

.logout-section:hover .logout-icon {
  filter: brightness(0.8);
}

.loading-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.error-auth {
  width: 100%;
  border-radius: 30px;
  background: #f75656f7;
  margin-top: 1.5rem;
  color: white;
}

.close-button, .skip-button, .advance-button {
  background: none;
  border: none;
  color: #7f7f7f;
  font-size: 28px;
  cursor: pointer;
}