/* General Editor Styles */
.code-editor-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  color: white;
  height: 100vh;
}

/* Tabs */
.code-tabs {
  display: flex;
  margin-bottom: 10px;
}

.code-tab {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #333;
  color: white;
  border: 1px solid #444;
}

.code-tab.active {
  background-color: #007acc;
}

.code-tab:not(.active):hover {
  background-color: #555;
}

/* Editor */
.editor-container{
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* Play Button */
.play-button {
  background-color: #007acc;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s;
}

.play-button:hover {
  background-color: #005a9c;
}

/* Code Editor Styles */
.cm-theme-one-dark {
  height: 300px;
  width: 100%;
  background-color: #1e1e1e;
  color: white;
  border: 1px solid #444;
}

/* Preview Container */
.preview-container {
  margin-top: 20px;
  width: 100%;
  height: 400px;
  border: 15px solid #444;
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 15px;
}

.preview-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

/* Botones de acción */
.action-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.clear-button, .save-button {
  background-color: #333;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.clear-button:hover {
  background-color: #d9534f;
}

.save-button:hover {
  background-color: #5cb85c;
}

